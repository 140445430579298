import { BASEURL } from './baseurl';

export const cantidadNotificaciones = () => {

	const params = {
		a: 'gnv',
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};

 export const listarNotificaciones = (pag, idUsuario, sm) => {

	const params = {
		a: 'ln',
        p:pag,
		id:idUsuario
	}
	if(sm){
		params.sm = sm;
	}

	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};
/*
export const listarNotificaciones = (pag, idUsuario, sm) => {
    const params = {
        a: 'ln',
        p: pag,
        id: idUsuario,
    };
    if (sm) {
        params.sm = sm;
    }
	console.log(pag)
    const mockResponse = {
        "Notificaciones": [
            { "id": "2198", "asunto": "Tercer mensaje", "texto": "<p>Tercer Mensaje<\/p>", "img": "", "thumb": "", "link": null, "visto": "1", "visitado": "0", "fecha": "2024-09-06 15:32:56", "mensaje": "1", "usuario_origen": "DESARROLLO 1" },
            { "id": "2197", "asunto": "Otro mensaje", "texto": "<p>Otro Mensaje<\/p>", "img": "", "thumb": "", "link": null, "visto": "1", "visitado": "1", "fecha": "2024-09-06 15:32:43", "mensaje": "1", "usuario_origen": "DESARROLLO 1" },
            // Más notificaciones...
        ],
        "Total": 120
    };

    // Asegúrate de que esta función retorne una Promise
    return new Promise((resolve, reject) => {
        // Simula un pequeño retraso para parecer una llamada asíncrona real
        setTimeout(() => {
            resolve(mockResponse);  // Resuelve con el mockResponse
        }, 1000);
    });
};*/


export const visitarNotificacion = (idNotificacion) => {
	const data = new FormData();
	data.append('a', 'snv');
	data.append('id', idNotificacion);

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}

export const notificacionesVistas = () => {
	const data = new FormData();
	data.append('a', 'svc');

	return fetch(BASEURL, {
		method: 'POST',
		body: data
	}).then(response => response.json());
}

export const getDetalleNotificacion = (id) => {

	const params = {
		a: 'gnd',
        id:id
	}
	let u = new URLSearchParams(params).toString();
	const url = BASEURL + '?' + u;

	const request = {
		method: 'GET',
	};

	return fetch(url, request)
		.then(response => response.json());

};